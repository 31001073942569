import React from "react";
import * as PropTypes from "prop-types";
import {Icon} from "components/lib/Icon";
import {sprintf} from 'sprintf-js';
import {isNullOrUndefined} from "lib/isNullOrUndefined";

export class ButtonWithBackground extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        hint: PropTypes.string,
        href: PropTypes.string.isRequired,

        setBackgroundColor: PropTypes.func.isRequired,
    }

    handleOnHover = () => {
        this.props.setBackgroundColor(this.props.name);
    }

    handleOnNoHover = () => {
        this.props.setBackgroundColor(null);
    }

    render() {
        let hint = this.props.hint;
        if (isNullOrUndefined(this.props.hint)) {
            hint = sprintf("%s profile", this.props.name);
        }
        const props = {
            title: sprintf("link to %s", hint),
            href: this.props.href,
            onPointerEnter: this.handleOnHover,
            onPointerLeave: this.handleOnNoHover,
        }

        return <a {...props}><Icon name={this.props.name} /></a>
    }
}
