import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';
import { isNotNullOrUndefined } from 'lib/isNullOrUndefined';

export class Icon extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        solid: PropTypes.bool,
        multiplier: PropTypes.number,

        className: PropTypes.string,
    };

    static defaultProps = {
        solid: false,
        multiplier: null,
        className: null,
    };

    render() {
        let classes = [];

        const { name, solid, multiplier } = this.props;

        if (solid) {
            classes = _.concat(classes, 'las');
        } else {
            classes = _.concat(classes, 'la');
        }

        classes = _.concat(classes, sprintf('la-%s', name));

        if (isNotNullOrUndefined(multiplier)) {
            classes = _.concat(classes, sprintf('la-%dx', multiplier));
        }

        let className = "anticon";
        if (isNotNullOrUndefined(this.props.className)) {
            className += " " + this.props.className;
        }

        return (
            <span role="img" className={className}>
                <i className={classes.join(' ')} />
            </span>
        );
    }
}
