import React from "react";

export const NamePronounciation = () => {
    return <React.Fragment>
        <p className="App-pronunciation-details" aria-labelledby="pronunciation">
            <span><b aria-labelledby="pronunciation">/miˑʃˑeaˑ/</b></span>
            <span className="separator">&mdash;&mdash;{' '}</span>
            <span>
                <b aria-labelledby="pronunciation">Mee</b>t (/mi/) &mdash;{' '}
                <b aria-labelledby="pronunciation">she</b>riff (/ʃe/) &mdash;{' '}
                h<b aria-labelledby="pronunciation">a</b>t (/a/)
            </span>
        </p>
        <div className="App-invisible" id="pronunciation">
            Pronunciation tip for my name: Start with the beginning sound of: meet; then continue with the starting
            sound of: sheriff; and finally use the A sound from middle of: hat. Should approximately sound like:
            Meee-shea-uh
        </div>
    </React.Fragment>
}
