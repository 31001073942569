import React from 'react';
import logo from './picture.blurred.jpg';
import 'App.scss';
import {sprintf} from 'sprintf-js';
import {isNotNullOrUndefined, isNullOrUndefined} from "lib/isNullOrUndefined";
import {NamePronounciation} from "components/NamePronounciation";
import {EMAIL} from "lib/data";
import {ButtonWithBackground} from "components/ButtonWithBackground";


class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      backgroundShowing: false,
      backgroundClassName: null,
      profilePicture: null,
    }
  }

  setBackgroundColor = name => {
    this.setState(state => {
      if (isNullOrUndefined(name)) {
        return {
          ...state,
          backgroundShowing: false,
        }
      }
      return {
        ...state,
        backgroundShowing: true,
        backgroundClassName: sprintf('App-bg-%s', name),
      }
    })
  }

  componentDidMount() {
    fetch('/picture-jpg.b64.txt').then(response => {
      if (!response.ok) {
        return;
      }

      response.text().then(data => {
        this.setState(state => {
          return {
            ...state,
            profilePicture: 'data:image/jpeg;base64,' + data,
          }
        })
      })
    })
  }

  render() {
    const {
      backgroundShowing,
      backgroundClassName,
      profilePicture,
    } = this.state;

    let appClassNames = ['App-bg'];
    if (backgroundShowing) {
      appClassNames.push('App-bg-transparent')
    }

    let realPicture = <img alt="" className="App-invisible" />;
    if (isNotNullOrUndefined(profilePicture)) {
      realPicture = <img alt="Franck Michea Headshot" src={profilePicture} />;
    }

    let profilePicturePlaceholderOpacity = 100;
    if (isNotNullOrUndefined(profilePicture)) {
      profilePicturePlaceholderOpacity = 0;
    }

    let backgroundClasses = ["App-bg-container"];
    if (isNotNullOrUndefined(backgroundClassName) ) {
      backgroundClasses.push(backgroundClassName);
    }

    const ButtonWithBackgroundBuilt = (props) => (
        <ButtonWithBackground {...props} setBackgroundColor={this.setBackgroundColor} />
    );

    return (<React.Fragment>
          <div className={backgroundClasses.join(' ')}>
            <div className={appClassNames.join(' ')} />
          </div>

          <div className="App">
            <div className="App-header">
              <div className="App-logo">
                {realPicture}
                <img src={logo} alt="Franck Michea Headshot" style={{opacity: profilePicturePlaceholderOpacity}} />
              </div>

              <p className="App-title">
                <h1 className="App-title-name">Franck Michea</h1>
                <div className="App-title-pronunciation">
                  <NamePronounciation />
                </div>
                <div className="App-title-email">
                  <a aria-labelledby="App-email-descriptor" href={sprintf("mailto:%s", EMAIL)}>{EMAIL}</a>
                </div>
              </p>

              <p className={"App-header-icons"}>
                {/*<ButtonWithBackgroundBuilt name="twitter" href="https://twitter.com/98810f8c06" />*/}
                <ButtonWithBackgroundBuilt name="rss" hint="blog" href="https://f.michea.dev/blog/" />
                {/*<ButtonWithBackgroundBuilt name="instagram" href="https://instagram.com/98810f8c06" />*/}
                {/*<ButtonWithBackgroundBuilt name="flickr" href="https://www.flickr.com/people/124676653@N03/" />*/}
                <ButtonWithBackgroundBuilt name="github" href="https://github.com/fmichea" />
                <ButtonWithBackgroundBuilt name="linkedin" href="https://www.linkedin.com/in/franck-michea-8998171a/" />
              </p>
            </div>
          </div>

          <p className={"App-no-js"}>
            <a href="/nojs.html">I don't like javascript</a>
          </p>

          <p id="App-email-descriptor" className="App-invisible">{sprintf("contact me by email: %s", EMAIL)}</p>
    </React.Fragment>
    );
  }
}

export default App;
